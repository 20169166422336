<script>
import FaqQuestion from '@components/elements/faq-question.vue';

export default {
    name: "Faq",
    components: {
        FaqQuestion,
    },
    data() {
        return {
            questions: [
                {
                    title: 'Что такое геоаналитика?',
                    text: 'Геоаналитика — один из самых эффективных инструментов Big Data для бизнеса, организаций из сферы науки и образования, а также госсектора. Это методология получения информации из данных о местоположении для ответа на пространственные вопросы'
                },
                {
                    title: 'Откуда берутся данные в Геоэффект?',
                    text: 'В основе продукта используются технические данные оператора мобильной связи МТС. Информация на ежедневной основе собирается, обрабатывается математическими и аналитическими алгоритмами BigData и подготавливается для использования'
                },
                {
                    title: 'Насколько данные точны?',
                    text: 'Точность и полнота данных напрямую зависят от качества покрытия сотовой сети на анализируемой территории и периода наблюдений'
                },
                {
                    title: 'Как происходит фильтрация данных?',
                    text: 'Все доступные для анализа в продукте фильтры рассчитываются собственными ML-моделями BigData, учитывается поведение абонентов в течение длительного периода времени. Характеристики абонентов регулярно пересчитываются и поддерживаются в актуальном состоянии'
                },
                {
                    title: 'Кому может быть полезен Геоэффект?',
                    text: 'Геоэффект может принести пользу любой отрасли, так как с нашей помощью ваша компания может точно и быстро определить направление своего развития, анализировать прибыльность в отдельных регионах, районах или локациях города'
                },
            ]
        }
    }
}
</script>

<template>
    <div id="faq" class="faq">
        <div class="faq-container">
            <h2 class="faq-title">Вопросы и ответы</h2>
            <div class="faq-questions-container">
                <FaqQuestion
                    v-for="(question, i) in questions"
                    :key="i"
                    :title="question.title"
                    :text="question.text"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/faq.scss';
</style>
