<script>
import {dataLayerEvent} from "@utils/data-layer";

export default {
    name: "HowItWorks",
    methods: {
        buttonClickEvent() {
            dataLayerEvent({
                eventId: 9,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: 'ostavit_zayavku',
                eventAction: 'button_click',
                buttonLocation: 'how-it-works'
            });
        }
    }
}
</script>

<template>
    <div id="how-it-works" class="how-it-works">
        <h2 class="how-it-works-title">
            Как это работает
        </h2>
        <div class="how-it-works-description">
            В основе — ежедневно собираемые технические данные оператора МТС, которые обрабатываются математическими и аналитическими алгоритмами BigData
        </div>
        <div class="how-it-works-blocks">
            <div class="how-it-works-block">
                <div class="how-it-works-block-header">
                    <h3 class="how-it-works-block-title">Какие данные</h3>
                    <div class="how-it-works-block-text">Очищенные и обработанные данные realtime потока событий на сотовой сети, накопленные с начала 2020 года. Определение характеристик перемещений жителей и туристов с точностью геопозиционирования по квадратам 62.5 х 62.5м</div>
                </div>
                <div class="how-it-works-block-img how-it-works-block-img-1"></div>
            </div>
            <div class="how-it-works-block">
                <div class="how-it-works-block-header">
                    <h3 class="how-it-works-block-title">Какие технологии</h3>
                    <div class="how-it-works-block-text">Обработка и хранение на кластерах Hadoop, витрины и отображение в OLAP и BI решениях. Мощь искусственного интеллекта для точного определения характеристик абонентов</div>
                </div>
                <div class="how-it-works-block-img how-it-works-block-img-2"></div>
            </div>
            <div class="how-it-works-block">
                <div class="how-it-works-block-header">
                    <h3 class="how-it-works-block-title">Кто реализовывает</h3>
                    <div class="how-it-works-block-text">Сплоченная команда профессионалов, готовая решить любую задачу в заданные сроки</div>
                </div>
                <div class="how-it-works-block-img how-it-works-block-img-3"></div>
            </div>
        </div>
        <div class="how-it-works-button-container">
            <button
                class="how-it-works-button"
                data-product="GeoEffect"
                data-vitrine="Геоэффект"
                data-btn-view="hidden"
                data-bo-hw-lead-button="true"
                data-bo-required-inn="true"
                data-bo-not-required="email"
                data-bo-subline=""
                data-btn-text="Оставить заявку"
                @click="buttonClickEvent"
            >
                <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/how-it-works.scss';
</style>
