<script>
require("@src/vendor/imask");

export default {
    name: "Contacts",
    data() {
        return {
            phoneNumber: '',
            phoneModalOpened: false,
            backgroundOpened: false,
        };
    },
    mounted() {
        // Маска отключена до разработки функционала
        // const phoneInput = this.$refs['phone-input'];
        // const maskOptions = {
        //     mask: '+7(000)000-00-00',
        //     lazy: true,
        //     prepare: (appended, masked) => {
        //         if (appended === '8' && masked.value === '') {
        //             return '+7';
        //         }
        //         return appended;
        //     },
        // }
        // new IMask(phoneInput, maskOptions);
    },
    watch: {
        phoneNumber(newVal, oldVal) {
            if (newVal.replace('+', '').length > 15) {
                this.phoneNumber = newVal.substring(0, newVal.length - 1);
            }
        }
    },
    computed: {
        formattedPhoneNumber() {
            const phoneNumberWithoutPlus = this.phoneNumber.replace('+', '');
            if (phoneNumberWithoutPlus.length > 15) {
                phoneNumberWithoutPlus.slice(0, 15);
            }
            return phoneNumberWithoutPlus;
        },
    },
    methods: {
        togglePhoneModal() {
            this.phoneModalOpened = !this.phoneModalOpened;
            this.backgroundOpened = this.phoneModalOpened;
        }
    }
}
</script>

<template>
    <div class="contacts">
        <!--    Скрыто до разработки функционала -->
<!--        <div-->
<!--            class="contacts-modal-layout"-->
<!--            :class="{ 'contacts-modal-layout-opened': backgroundOpened }"-->
<!--            @click.self="togglePhoneModal"-->
<!--        >-->
<!--            <div-->
<!--                class="contacts-modal-layout-container"-->
<!--                @click.self="togglePhoneModal"-->
<!--            >-->
<!--                <div-->
<!--                    class="contacts-phone-modal-container-anim"-->
<!--                    :class="{ active: phoneModalOpened }"-->
<!--                >-->
<!--                    <div-->
<!--                        class="contacts-phone-modal"-->
<!--                        :class="{ active: phoneModalOpened }"-->
<!--                    >-->
<!--                        <div class="contacts-phone-modal-container">-->
<!--                            <div class="contacts-phone-modal-text">-->
<!--                                Перезвоним и поможем выбрать наиболее подходящий для вас сервис-->
<!--                            </div>-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                class="contacts-phone-modal-input"-->
<!--                                ref="phone-input"-->
<!--                                v-model="phoneNumber"-->
<!--                                placeholder="+7"-->
<!--                            />-->
<!--                            <button-->
<!--                                class="contacts-phone-modal-button"-->
<!--                                :disabled="formattedPhoneNumber.length < 15"-->
<!--                                @click="togglePhoneModal"-->
<!--                            >-->
<!--                                Жду звонка-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="contacts-buttons-layout">
            <div class="contacts-buttons-layout-container">
                <div class="contacts-buttons">
<!--                <div-->
<!--                    class="contacts-button"-->
<!--                    @click="togglePhoneModal"-->
<!--                >-->
<!--                    <div class="contacts-button-phone"></div>-->
<!--                </div>-->
                <a
                    class="contacts-button"
                    href="https://t.me/geoeffect_bot"
                    target="_blank"
                >
                    <div class="contacts-button-tg"></div>
                </a>
            </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/components/contacts.scss';
</style>
