<script>
import { dataLayerEvent } from '@utils/data-layer.js';

export default {
    name: "Documents",

    data() {
        return {
            currentYear: new Date().getFullYear(),
        }
    },
    methods: {
        buttonClickEvent(label) {
            dataLayerEvent({
                eventId: 9,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: label,
                eventAction: 'button_click',
                buttonLocation: 'documents'
            });
        },
        clickOnLinkEvent(event) {
            event.preventDefault();
            const label = event.target.getAttribute('data-label');
            dataLayerEvent({
                eventId: 10,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: label,
                eventAction: 'link_click'
            });

            const url = event.target.href;
            if(url) {
                window.open(url, '_blank').focus();
            }
        },
        clickOnDocsEvent(label) {
            dataLayerEvent({
                eventId: 11,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: label,
                eventAction: 'doc_click'
            });
        }
    }
}
</script>

<template>
    <div id="documents" class="row">
        <div class="col p-0">
            <div class="full-site-footer">
                <div class="footer-wrap row">
                    <div class="footer-info">
                        <div class="footer-info-title">
                            Геоэффект
                        </div>
                        <div class="footer-info-text">
                            Геоэффект — сервис для геоаналитики и геомаркетинга для бизнеса. <br>
                            Воспользуйтесь нашим продуктом по оценке и анализу локаций онлайн. Изучайте аудиторию и делайте выбор на основе геоданных о населении и турпотоке!
                        </div>
                    </div>
                </div>
                <div class="footer-wrap">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3 mb-6">
                            <p class="footer-subtitle mb-4">Контакты</p>
<!--                            Скрыто до получения реального телефона -->
<!--                            <div class="footer-contact">-->
<!--                                <div class="footer-contact-icon footer-contact-icon-phone"></div>-->
<!--                                <a-->
<!--                                    class="footer-contact-link"-->
<!--                                    href="#"-->
<!--                                >-->
<!--                                    8-888-888-88-88-->
<!--                                </a>-->
<!--                            </div>-->
                            <div class="footer-contact">
                                <div class="footer-contact-icon footer-contact-icon-mail"></div>
                                <a
                                    class="footer-contact-link"
                                    href="mailto:geoeffect@mts.ru"
                                >
                                    geoeffect@mts.ru
                                </a>
                            </div>
                            <a
                                class="footer-contact-icon-tg"
                                href="https://t.me/geoeffect_bot"
                                target="_blank"
                            ></a>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-6">
                            <p class="footer-subtitle mb-4">Инструкция по подключению:</p>
                            <ol class="mt-1">
                                <li>
                                    <p class="btn-text"
                                       data-product="GeoEffect"
                                       data-vitrine="Геоэффект"
                                       data-btn-view="hidden"
                                       data-bo-hw-lead-button="true"
                                       data-bo-required-inn="true"
                                       data-bo-not-required="email"
                                       data-bo-subline=""
                                       data-btn-text="Оставьте заявку"
                                       @click="buttonClickEvent('ostavte_zayavku')"
                                    >
                                        <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
                                    </p>
                                </li>
                                <li>Оформите договор</li>
                                <li>Получите доступ на платформу</li>
                            </ol>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-6">
                            <p class="footer-subtitle mb-4">Ценообразование:</p>
                            <p>Стоимость услуг зависит от параметров анализа и доступа. <br>
                                <span class="btn-text"
                                      data-product="GeoEffect"
                                      data-vitrine="Геоэффект"
                                      data-btn-view="hidden"
                                      data-bo-hw-lead-button="true"
                                      data-bo-required-inn="true"
                                      data-bo-not-required="email"
                                      data-bo-subline=""
                                      data-btn-text="Напишите нам"
                                      @click="buttonClickEvent('napishite_nam')"
                                ><img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/></span>, опишите задачу и мы поможем вам выбрать
                                оптимальное решение</p>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-6">
                            <p class="footer-subtitle mb-4">Документация:</p>
                            <p class="mb-4">Система «Геоэффект» внесена в
                                <a
                                    href="https://reestr.digital.gov.ru/reestr/1525692/"
                                    data-label="reestr_otechestvennogo_po"
                                    @click="clickOnLinkEvent"
                                >
                                    Реестр отечественного ПО
                                </a>
                            </p>
                            <div class="docs">
                                <a
                                    class="file icon icon-doc"
                                    href="/assets/files/description_of_the_functional_characteristics_of_the_software_21.03.docx"
                                    @click="clickOnDocsEvent('funkcionalnye_harakteristiki_po')"
                                >
                                    <span>
                                        Функциональные характеристики ПО
                                    </span>
                                </a>
                                <a
                                    class="file icon icon-doc"
                                    href="/assets/files/installation_and_operation_requirements_21.03.docx"
                                    @click="clickOnDocsEvent('trebovaniya_po_ustanovke_i_ekspluatacii')"
                                >
                                    <span>
                                        Требования по установке и эксплуатации
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 px-4">
                    <div class="col-12">
                        <p>© {{ currentYear }}  ПАО «МТС». Все права защищены </p>
                    </div>
                </div>
            </div>
        </div>
        <div ref="script-container"></div>
    </div>
</template>

<style lang="scss">
    @import '@design/components/full-site-footer.scss';
</style>
