<script>
import { dataLayerEvent } from '@utils/data-layer.js';
import store from "@state/store";

export default {
    name: "PageHeader",
    props: {
        links: {
            type: Array,
            default: () => {}
        },
        logoLink: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        showLoginButton: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            mobileNavigationBg: false,
        };
    },
    mounted() {
        dataLayerEvent({
            eventId: 1,
            event: 'scrn',
            actionGroup: 'non_interactions'
        });

        document.addEventListener('click', this.checkAllElementsClick);
        const hash = window.location.hash.replace(/^#/, '');
        if(hash) {
            window.scroll({
                top: document.getElementById(hash).getBoundingClientRect().top + window.scrollY - 60,
                behavior: 'auto'
            });
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.checkAllElementsClick);
    },
    methods: {
        checkPage(name) {
            if (this.$route.name === name) {
                return 'menu-link-active';
            }
        },
        closeMenu() {
            this.mobileNavigationBg = false;
        },
        scrollToBottom(label) {
            this.mobileNavigationBg = false;
            store.dispatch('modal/setActiveModalWindow', null);
            window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth'
            });
            dataLayerEvent({
                eventId: 5,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: label,
                eventAction: 'menu_click'
            });
        },
        checkAllElementsClick(e) {
            const id = e.target?.getAttribute('id');
            if (id === 'ZVK_popup_bkgd') {
                dataLayerEvent({
                    eventId: 12,
                    event: 'vntGeo',
                    eventCategory: 'glavnaya',
                    eventLabel: 'zakryt',
                    eventAction: 'element_click'
                });
            }

            if (e.target && e.target.parentElement) {
                const parent = e.target.parentElement;
                if (parent.id === 'ZVK_popup_x') {
                    dataLayerEvent({
                        eventId: 12,
                        event: 'vntGeo',
                        eventCategory: 'glavnaya',
                        eventLabel: 'zakryt',
                        eventAction: 'element_click'
                    });
                } else if (parent.id === 'ZVK_form_submit-button' || e.target.id === 'ZVK_form_submit-button') {
                    dataLayerEvent({
                        eventId: 13,
                        event: 'vntGeo',
                        eventCategory: 'glavnaya',
                        eventLabel: 'ostavit_zayavku',
                        eventAction: 'button_click'
                    });
                }
            }
        },
        toMainPageEvent(event) {
            event.preventDefault();
            dataLayerEvent({
                eventId: 4,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: 'logo',
                eventAction: 'element_click'
            });

            if (this.$route.name !== 'home') {
                window.location.href = '/';
            }
        },
        toLoginEvent(event) {
            event.preventDefault();
            if (this.isMobile) {
                store.dispatch('modal/setActiveModalWindow', 'MobileBlock')
                return;
            }

            dataLayerEvent({
                eventId: 2,
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventLabel: 'vhod',
                eventAction: 'button_click'
            });

            window.location.href = event.target.href;
        },
        toHeaderLinkEvent(event) {
            event.preventDefault();
            const label = event.target.getAttribute('data-label');
            dataLayerEvent({
                eventId: 5,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: label,
                eventAction: 'menu_click'
            });

            window.location.href = `/${event.target.getAttribute('data-href')}`;
        }
    }
}
</script>

<template>
    <div class="row">
        <div class="col p-0">
            <div class="header px-32 d-flex justify-content-between w-100" :class="{ 'mobile-menu-active': mobileNavigationBg }">
                <div class="left-col d-flex w-100 align-items-center">
                    <div class="mobile-navigation">
                        <nav role="navigation">
                            <div class="menu-toggle">
                                <input type="checkbox" v-model="mobileNavigationBg"/>
                                <span></span>
                                <span></span>
                                <span></span>
                                <ul class="mobile-menu">
                                    <li
                                        v-for="(link, i) in links"
                                        :key="i"
                                    >
                                        <a
                                            v-if="link.external || link.link"
                                            :href="link.link"
                                            :data-href="link.link"
                                            :data-label="link.analyticsLabel"
                                            @click="toHeaderLinkEvent"
                                        >
                                            {{link.text}}
                                        </a>
                                        <a
                                            v-else
                                            @click="scrollToBottom(link.analyticsLabel)"
                                        >
                                            {{link.text}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="logo-block d-flex align-items-center">
                        <a
                            class="logo"
                            href="/"
                            @click="toMainPageEvent"
                        ></a>
                    </div>
                    <div class="desktop-navigation">
                        <nav class="navigation">
                            <template
                                v-for="link in links"
                            >
                                <a
                                    v-if="link.external || link.link"
                                    :class="checkPage(link.link)"
                                    :href="link.link"
                                    :data-href="link.link"
                                    :data-label="link.analyticsLabel"
                                    @click="toHeaderLinkEvent"
                                >
                                    {{link.text}}
                                </a>
                                <a
                                    v-else
                                    @click="scrollToBottom(link.analyticsLabel)"
                                >
                                    {{link.text}}
                                </a>
                            </template>
                        </nav>
                    </div>
                </div>
                <div
                    v-if="showLoginButton"
                    class="right-col d-flex align-items-center justify-content-end"
                >
                    <a
                        class="btn"
                        href="https://system.geoeffect.ru/"
                        @click="toLoginEvent"
                    >
                        Вход
                    </a>
                </div>
            </div>
            <div
                v-if="mobileNavigationBg"
                class="mobile-navigation-bg"
                @click="closeMenu"
            ></div>
        </div>
    </div>
</template>

<style lang="scss">
    // noinspection CssUnknownTarget
    @import '@design/components/header.scss';
</style>
