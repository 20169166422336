export const state = {
    activeModalWindow: ''
}

export const getters = {
    getActiveModalWindow(state) {
        return state.activeModalWindow;
    },
}

export const mutations = {
    setActiveModalWindow(state, modalWindow) {
        state.activeModalWindow = modalWindow;
    }
}

export const actions = {
    setActiveModalWindow({commit}, modalWindow) {
        commit('setActiveModalWindow', modalWindow);
    },
}
