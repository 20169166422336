<script>
import appConfig from '@src/app.config'
import scrollEvent from '@utils/scroll-animation.js'
import PageHeader from "@components/page-header.vue";
import ModalContainer from "@components/modal-container.vue";
import ModalWindows from "@components/modal-windows.vue";
import Contacts from "@components/contacts.vue";
import MainScreenPagesSection from "@components/sections/main-screen-pages.vue";
import DataPresentationPagesSection from "@components/sections/data-presentation-pages.vue";
import IndustriesSection from "@components/sections/industries.vue";
import ServicesSection from "@components/sections/services.vue";
import HowItWorksSection from "@components/sections/how-it-works.vue";
import InvestmentSection from "@components/sections/investment.vue";
import JustTrySection from "@components/sections/just-try.vue";
import ArticlesSection from "@components/sections/articles.vue";
import QuestionSection from "@components/sections/question.vue";
import FaqSection from "@components/sections/faq.vue";
import DocumentsSection from "@components/sections/documents.vue";
import FullSiteFooter from '@components/full-site-footer.vue'
import CookiesWarning from '@components/elements/cookies-warning.vue'
import Layout from '@layouts/main.vue'
import {mapGetters} from "vuex";

export default {
    name: 'Visiting',
    page: {
        title: appConfig.visiting.title,
        meta: [{name: 'description', content: appConfig.visiting.description}],
        link: [{ rel: 'canonical', href: appConfig.visiting.canonical }]
    },
    components: {
        PageHeader,
        ModalContainer,
        ModalWindows,
        Contacts,
        MainScreenPagesSection,
        DataPresentationPagesSection,
        IndustriesSection,
        ServicesSection,
        HowItWorksSection,
        InvestmentSection,
        JustTrySection,
        ArticlesSection,
        QuestionSection,
        FaqSection,
        DocumentsSection,
        FullSiteFooter,
        CookiesWarning,
        Layout,
    },
    data() {
        return {
            windowsWidth: window.innerWidth,
            topTitle: 'Посещаемость мероприятий, \n объектов и территорий',
            topText: 'Анализируйте события и мероприятия, исследуйте посещаемость объектов и территорий, отслеживайте динамику изменений',
            slides: [
                '/assets/images/carousels/visiting/1.webp',
                '/assets/images/carousels/visiting/2.webp',
                '/assets/images/carousels/visiting/3.webp',
                '/assets/images/carousels/visiting/4.webp',
                '/assets/images/carousels/visiting/5.webp',
            ],
            dataPresentationBlocks: [
                'Исследуйте <span>объекты</span> и&nbsp;<span>территории</span>',
                'Анализируйте ваши <span>события</span>',
                'On-line <span>мониторинг</span>',
            ],
            dataPresentationBlocksAdditional: [
                'Определите посещаемость объектов или&nbsp;территорий и&nbsp;получите рейтинг их популярности для планирования развития инфраструктуры',
                'Оценивайте посещаемость культурно-массовых мероприятий и&nbsp;анализируйте портрет аудитории',
                'Наблюдайте за количеством посетителей объектов, мероприятий и&nbsp;территорий в&nbsp;моменте для&nbsp;принятия организационных решений',
            ],
            justTryBlockData: {
                serviceName: 'Посещаемость мероприятий, \nобъектов и территорий',
                imgNum: 2,
            },
            services: [
                {
                    title: 'Цифровой житель',
                    description: 'Оцените спрос на инфраструктуру с помощью данных о профиле, численности и плотности населения',
                    background: 'tab-map',
                    link: 'citizen',
                },
                {
                    title: 'Цифровой туризм',
                    description: 'Повышайте потенциал туристической отрасли, анализируя турпоток в регионе',
                    background: 'tab-tourism',
                    link: 'tourist',
                },
                {
                    title: 'Геоэффект бизнес',
                    description: 'Выбирайте лучшие места для открытия новых точек продаж и развития сети. Сравнивайте и анализируйте трафик и конкурентов',
                    background: 'tab-geo',
                    link: 'business',
                },
            ],
            mobileNavigationBg: false,
            headerLinks: [
                {
                    text: 'Цифровой житель',
                    external: false,
                    link: 'citizen',
                    analyticsLabel: 'cifrovoi_zhitel',
                },
                {
                    text: 'Цифровой туризм',
                    external: false,
                    link: 'tourist',
                    analyticsLabel: 'cifrovoi_turizm',
                },
                {
                    text: 'Посещаемость',
                    external: false,
                    link: 'visiting',
                    analyticsLabel: 'poseschaemost',
                },
                {
                    text: 'Геоэффект бизнес',
                    external: false,
                    link: 'business',
                    analyticsLabel: 'geoeffekt_biznes',
                },
                {
                    text: 'Документация',
                    external: false,
                    link: null,
                    analyticsLabel: 'dokumentaciya',
                },
            ],
        }
    },
    created() {
        window.addEventListener("scroll", scrollEvent);
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.addEventListener('resize', this.onResize);
        document.body.style.overflow = 'visible';
    },
    computed: {
        ...mapGetters({
            getActiveModalWindow: 'modal/getActiveModalWindow',
        }),
        isMobile() {
            return this.windowsWidth <= 1000;
        }
    },
    methods: {
        onResize() {
            this.windowsWidth = window.innerWidth;
        },
    },
}
</script>

<template>
    <Layout class="landing-page">
        <PageHeader
            :links="headerLinks"
            :logo-link="false"
            :is-mobile="isMobile"
            :show-login-button="true"
        />

<!--        <ModalContainer />-->

        <ModalWindows
            :open-window="getActiveModalWindow"
        />

        <Contacts />

        <div id="main-page" class="city-map">
            <MainScreenPagesSection
                :title="topTitle"
                :text="topText"
                :slides="slides"
            />

            <DataPresentationPagesSection
                :blocks="dataPresentationBlocks"
                :additional-text="dataPresentationBlocksAdditional"
                :block-minimal-width-tablet="304"
                :small-title="true"
            />
        </div>

        <IndustriesSection />

        <ServicesSection :services="services" />

        <HowItWorksSection />

<!--        <InvestmentSection />-->

        <JustTrySection
            :service-name="justTryBlockData.serviceName"
            :img-num="justTryBlockData.imgNum"
        />

        <ArticlesSection />

        <QuestionSection />

        <FaqSection />

        <DocumentsSection />

        <CookiesWarning />
    </Layout>
</template>

<style lang="scss">
    @import '@design/components/header.scss';
    @import '@design/components/full-site-footer.scss';
    @import '@design/landing.scss';
</style>

