<script>
export default {
    name: "DataPresentationPages",
    props: {
        blocks: {
            type: Array,
            default: () => []
        },
        additionalText: {
            type: Array,
            default: () => []
        },
        blockMinimalWidthTablet: {
            type: Number,
            default: null,
        },
        smallTitle: {
            type: Boolean,
            default: false,
        },
        compact: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        blockMinimalWidthTabletStyle() {
            if (!this.blockMinimalWidthTablet) {
                return {};
            }
            return {
                'min-width': `${this.blockMinimalWidthTablet}px`,
            }
        }
    }
}
</script>

<template>
    <div id="data-presentation" class="data-presentation-pages">
        <div
            class="data-presentation-pages-block-container animated"
            :class="{ 'compact': compact }"
        >
            <div
                v-for="(block, i) in blocks"
                :key="i"
                :style="blockMinimalWidthTabletStyle"
                class="data-presentation-pages-block"
            >
                <h2
                    v-html="block"
                    class="data-presentation-pages-block-title"
                    :class="{ 'small': smallTitle }"
                ></h2>
                <div
                    v-if="additionalText[i]"
                    v-html="additionalText[i]"
                    class="data-presentation-pages-block-additional"
                ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/data-presentation-pages.scss';
</style>
