<script>
import Ticker from '@components/elements/ticker.vue'
import {dataLayerEvent} from "@utils/data-layer";

export default {
    name: "Industries",

    components: {
        Ticker,
    },
    data() {
        return {
            tickerRows: [
                ['Ретейл', 'Банковский сектор', 'Транспорт и логистика', 'Медицина и фармацевтика', 'Туризм'],
                ['Почтовая и курьерская доставка', 'Культура', 'Инфраструктурное планирование', 'Образование']
            ]
        }
    },
    methods: {
        buttonClickEvent() {
            dataLayerEvent({
                eventId: 9,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: 'ostavit_zayavku',
                eventAction: 'button_click',
                buttonLocation: 'industries'
            });
        }
    }
}
</script>

<template>
    <div id="industries" class="industries">
        <div class="industries-container">
            <h2 class="industries-title">
                Решаем задачи для индустрий
            </h2>
            <div class="industries-text">
                Геоэффект — это универсальный сервис, который поможет решить сложные задачи в бизнесе или множестве других отраслей
            </div>
            <div class="industries-blocks-container">
                <div class="industries-blocks">
                <div class="industries-block">
                    <h3 class="industries-block-title">Ретейл</h3>
                    <div class="industries-block-text">Улучшить посещаемость сети и открыть новые точки в местах концентрации целевой аудитории</div>
                </div>
                <div class="industries-block">
                    <h3 class="industries-block-title">Туризм</h3>
                    <div class="industries-block-text">Оценить турпоток, улучшить инфраструктуру и&nbsp;повысить туристический потенциал региона</div>
                </div>
                <div class="industries-block">
                    <h3 class="industries-block-title">Инфраструктура</h3>
                    <div class="industries-block-text">Оценить места скопления людей в районе и&nbsp;выбрать лучшие локации для новых социальных объектов</div>
                </div>
            </div>
            </div>
            <div class="industries-button-container">
                <button
                    class="industries-button"
                    data-product="GeoEffect"
                    data-vitrine="Геоэффект"
                    data-btn-view="hidden"
                    data-bo-hw-lead-button="true"
                    data-bo-required-inn="true"
                    data-bo-not-required="email"
                    data-bo-subline=""
                    data-btn-text="Оставить заявку"
                    @click="buttonClickEvent"
                >
                    <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
                </button>
            </div>
        </div>
        <Ticker :rows="tickerRows" />
    </div>
</template>

<style lang="scss">
    @import '@design/sections/industries.scss';
</style>
