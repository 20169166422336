<script>
export default {
    name: "Article",
    props: {
        image: {
            type: String,
            default: ""
        },
        imageIsIcon: {
            type: Boolean,
            default: false
        },
        date: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: ""
        },
    },
    methods: {
        cutText(text) {
            if (text.length <= 68) {
                return text;
            }
            return text.slice(0, 65) + '...';
        }
    }
}
</script>

<template>
    <div class="article">
        <div
            class="article-img"
            :class="{ 'article-img-icon': imageIsIcon }"
            :style="{ backgroundImage: `url('/assets/images/articles/${image}')` }"
        >
            <div class="article-info">
                <div class="article-date">{{ date }}</div>
                <div class="article-text">{{ cutText(text) }}</div>
                <div class="article-text-full">{{ text }}</div>
                <a
                    :href="link"
                    target="_blank"
                    rel="nofollow"
                    class="article-link"
                >
                    Подробнее
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/article.scss';
</style>
