<script>
import store from "@state/store";

export default {
    name: "ModalWindows",
    props: {
        openWindow: {
            type: String,
            default: null
        },
    },
    methods: {
        close() {
            store.dispatch('modal/setActiveModalWindow', null)
        }
    }
}
</script>

<template>
    <div>
        <div
          v-if="openWindow === 'MobileBlock'"
          class="mobile-block-window"
        >
            <div class="mobile-block-window-title-container">
                <div class="mobile-block-window-title">
                    Геоэффект бизнес
                </div>
                <div class="mobile-block-window-info">
                    <div class="mobile-block-window-info-icon"></div>
                    <div class="mobile-block-window-info-text">
                        Сервис доступен через ПК.
                        <br>
                        Версия для телефонов и планшетов
                        <br>
                        скоро появится
                    </div>
                </div>
                <button
                  class="mobile-block-window-button"
                  @click="close"
                >
                    Назад
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/components/mobile-block-window.scss';
</style>
